import Cookies from 'js-cookie';

export default (gaOptOutKey, window) => () => {
    if (gaOptOutKey === null) return;

    Cookies.set(gaOptOutKey, true, {
        expires: new Date('2099-12-31T00:00:00.0Z'),
    });

    window[gaOptOutKey] = true;
};
