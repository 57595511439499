import Cookies from 'js-cookie';

import load from './load';
import optOut from './opt-out';

export const ScriptTagName = 'script';

export default (gaTrackingID, gaObjectName, window) => {
    if (gaTrackingID === null) {
        window.googleAnalyticsOptOut = optOut(null, window);

        return false;
    }

    const gaOptOutKey = `${gaObjectName}-disable-${gaTrackingID}`;

    window.googleAnalyticsOptOut = optOut(gaOptOutKey, window);

    if (Cookies.getJSON(gaOptOutKey) || false) {
        window[gaOptOutKey] = true;

        return false;
    }

    const gaObject = load(gaObjectName, window, document, ScriptTagName);

    gaObject('create', gaTrackingID, 'auto');
    gaObject('set', 'anonymizeIp', true);
    gaObject('send', 'pageview');

    return true;
};
