import {ScriptTagName} from './init';

const GTMObjectName = 'dataLayer';

export default (window, document, GTMIdentifier) => {
    if (GTMIdentifier === null) return;

    const gtmObject = window[GTMObjectName] = window[GTMObjectName] || [];
    const now = new Date();

    gtmObject.push({
        'gtm.start': now.getTime(),
        event: 'gtm.js',
    });

    const gtmScriptElement = document.createElement(ScriptTagName);
    const firstExistingScriptElement = document.getElementsByTagName(ScriptTagName)[0];

    gtmScriptElement.async = true;
    gtmScriptElement.src = `https://www.googletagmanager.com/gtm.js?id=${GTMIdentifier}`;

    firstExistingScriptElement.parentNode.insertBefore(gtmScriptElement, firstExistingScriptElement);
};
