const GoogleAnalyticsScriptURL = 'https://www.google-analytics.com/analytics.js';

export default (gaObjectName, window, document, tagName) => {
    window.GoogleAnalyticsObject = gaObjectName;

    const gaObject = window[gaObjectName] = window[gaObjectName] || ((...args) => {
        (window[gaObjectName].q = window[gaObjectName].q || []).push(args);
    });

    gaObject.l = 1 * new Date();

    const googleAnalyticsScriptElement = document.createElement(tagName);
    const firstExistingScriptElement = document.getElementsByTagName(tagName)[0];

    googleAnalyticsScriptElement.async = 1;
    googleAnalyticsScriptElement.src = GoogleAnalyticsScriptURL;

    firstExistingScriptElement.parentNode.insertBefore(googleAnalyticsScriptElement, firstExistingScriptElement);

    return gaObject;
};
